<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create New BigContainerFee</v-card-title>
    <v-row>
      <v-col cols="6"><v-text-field dense outlined label="BigContainerId" v-model="item.big_container_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="FeeName" v-model="item.fee_name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="FeeAmount" v-model="item.fee_amount"/></v-col>
</v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CreateBigContainerFee',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  computed: {
    ...mapGetters({
      roles: 'big_container_fee/all'
    }),
  },
  methods: {
    ...mapActions({
      createItem: 'big_container_fee/create'
    }),
    processAdd() {
      this.createItem(this.item).then(() => {
        this.onCreated();
      });
    }
  }
}
</script>
